<!--
/**
 * Intro template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import { routeTo, getAssetPathForIntro } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Data
    const state = reactive({
        step: 1,
        stepCount: 4
    });
</script>

<template>
    <div class="intro-wrapper">
        <div class="intro-content">

        </div>
    </div>
</template>
