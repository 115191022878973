<!--
/**
 * Station messenger template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { defineProps, computed, reactive } from 'vue';
    import store from '?/Stores';
    import { sanitizeUserInput } from '?/Composable/Tools';
    import repository from '?/Composable/Repository';

    // Data
    const props = defineProps({
        stationId: {
            type: Number,
            required: true
        }
    });

    const state = reactive({
        messenger: null,
        lastAnswer: null
    });

    const isWriting = computed(() => {
        return store.getters.isWritingForStation(props.stationId);
    });

    const answerExpected = computed(() => {
        return store.getters.answerExpectedforStation(props.stationId);
    });

    const answerRejected = computed(() => {
        return store.getters.answerRejectedforStation(props.stationId);
    });

    const buttonInputExpected = computed(() => {
        return store.getters.buttonInputExpectedForStation(props.stationId);
    });

    const buttons = computed(() => {
        return store.getters.buttonsForStation(props.stationId);
    });

    // Functions
    function sendAnswer (e) {
        let content = sanitizeUserInput(e.target.elements.answer.value);

        // Prevent sending an answer multiple times
        if (state.lastAnswer == content) {
            return;
        } else {
            state.lastAnswer = content;
        }

        // Do nothing with empty messages
        if (content == '') {
            return;
        }

        // Render and persist user message
        store.commit('addUserMessage', {
            stationId: props.stationId,
            content: content,
            timestamp: Date.now()
        });

        // Send user answer to backend
        repository.sendTextAnswerToStation(props.stationId, content)
            .then(() => {
                // Reset answer expected trigger
                store.commit('deleteStationFromAnswerExpected', props.stationId);

                // Reset answer rejected trigger
                store.commit('deleteStationFromAnswerRejected', props.stationId);
            })
            .finally(() => {
                // Reset messenger field value
                if (state.messenger) {
                    state.messenger.value = '';
                }

                // Reset last answer
                state.lastAnswer = null;
            });
    }

    function sendButtonAction (action) {
        // Do nothing with empty action
        if (action == '') {
            return;
        }

        // Send user answer to backend
        repository.sendButtonAnswerToStation(props.stationId, action)
            .then(() => {
                // Reset button input expected trigger
                store.commit('deleteStationFromButtonInputExpected', props.stationId);

                // Reset buttons
                store.commit('deleteButtonsFromStation', props.stationId);
            });
    }
</script>

<template>
    <div class="messenger-wrapper"
         :class="{ 'answer-rejected': answerRejected }">
        <div class="answer-textfield"
             :class="{'visible': ((answerExpected || answerRejected) && !isWriting), 'collapsed': buttonInputExpected}">
            <form v-on:submit.prevent="sendAnswer">
                <input type="text"
                       name="answer"
                       id="answer"
                       class="answer"
                       value=""
                       :ref="(el) => { state.messenger = el }"
                       :placeholder="$t('messages.message')"
                       :disabled="((!answerExpected && !answerRejected) || isWriting)" />
                <button type="submit"
                        v-on:click.once="return"
                        :disabled="((!answerExpected && !answerRejected) || isWriting)">
                    {{ $t('messages.send') }}
                </button>
            </form>
        </div>
        <div class="button-answer-wrapper"
             :class="{ 'answer-rejected': answerRejected }"
             v-if="buttonInputExpected && !isWriting">
            <button class="button-answer"
                    v-for="button in buttons"
                    v-on:click.once.prevent="sendButtonAction(button.target)"
                    :class="button.target">
                {{ button.text }}
            </button>
        </div>
    </div>
</template>
