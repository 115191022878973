<!--
/**
 * News template
 *
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed, reactive, onMounted, onUnmounted } from 'vue';
    import store from '?/Stores';
    import { news, station as stationData } from '?/ProjectData';
    import { getNearestStation, getUnseenStories, getAssetPathForStations } from '?/Composable/Tools';
    import Webcontent from '?/Component/Webapp/MessageTypes/Webcontent';
    import WebcontentRecommendation from '?/Component/Webapp/MessageTypes/WebcontentRecommendation';
    import Webtext from '?/Component/Webapp/MessageTypes/Webtext';
    import WebtextRecommendation from '?/Component/Webapp/MessageTypes/WebtextRecommendation';

    // Data
    const state = reactive({
        unseenNews: store.getters.news,
        activeStation: store.getters.currentStation,
        nearestStation: getNearestStation(),
        unseenStories: getUnseenStories()
    });

    const randomNews = computed(() => {
        if (news.length) {
            return news[Math.floor(Math.random()*news.length)];
        }

        return null;
    });

    // Custom setup logic
    onMounted(() => {
        store.commit('unseenNews', null);
    });

    onUnmounted(() => {
        store.commit('unseenNews', null);
        store.commit('deleteNewsMessages');
    });

    // Functions
    function component (type) {
        let component = null;

        switch(type) {
            case 'webcontent':
                component = markRaw(Webcontent);

                break;
            case 'webcontent-recommendation':
                component = markRaw(WebcontentRecommendation);

                break;
            case 'webtext':
                component = markRaw(Webtext);

                break;
            case 'webtext-recommendation':
                component = markRaw(WebtextRecommendation);

                break;
            default:
                component = markRaw(Webtext);
        };

        return component;
    }

    const hasNews = () => {
        state.activeStation = store.getters.currentStation;
        state.unseenNews = store.getters.news;

        return state.unseenNews.length ||
            state.nearestStation ||
            state.unseenStories.length ||
            state.activeStation;
    };

    const stationTitle = (station) => {
        let data = stationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data.hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : data[0].title
            : '';
    };
</script>

<template>
    <div class="news-wrapper">
        <template v-if="!hasNews()">
            <!-- Random news -->
            <div class="news-item" v-html="randomNews"></div>
        </template>

        <template v-else>
            <!-- Dynamically added news, must be in message format -->
            <div class="news-item"
                 v-if="state.unseenNews.length"
                 v-for="news in state.unseenNews">
                <component :is="component(news.type)"
                           :message="news"></component>
            </div>

            <!-- Show active station -->
            <div class="news-item active-station" v-if="state.activeStation">
                <router-link :to="'/webapp/station/' + state.activeStation.stationId">
                    <figure class="station-image">
                        <span class="station-image-wrapper"
                              :style="'background-image: url(\'' + getAssetPathForStations() + '/station-' + state.activeStation.stationId + '.jpg\');'">
                            <img :src="getAssetPathForStations() + '/station-' + state.activeStation.stationId + '.jpg'"
                                 alt="" />
                        </span>
                        <figcaption class="station-description"
                                    v-html="$t('news.station-active', {name: stationTitle(state.activeStation)})"></figcaption>
                    </figure>
                </router-link>
            </div>

            <!-- Show nearest station -->
            <div class="news-item" v-if="state.nearestStation">
                <router-link :to="'/webapp/station/' + state.nearestStation.stationId">
                    {{ $t('news.station-nearby') }}
                </router-link>
            </div>

            <!-- Show when new unread story is available -->
            <div class="news-item" v-if="state.unseenStories.length">
                <router-link :to="'/webapp/stories'">
                    {{ $t('news.new-story') }}
                </router-link>
            </div>
        </template>
    </div>
</template>
